import { useState, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation, Trans } from "react-i18next";
import axios from "axios";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { currency, dateFormat, timeFormat } from "../../../../../dynamicController";
import { saveTransactionId } from "../../../../../redux/actions/paymentActions";

const STORETYPE = process.env.REACT_APP_STORETYPE;
const apiUrl = process.env.REACT_APP_API_URL;

const mySwal = withReactContent(Swal);

const PagBank = ({ token, saveTransactionId }) => {
    const [paymentMethod, setPaymentMethod] = useState("DEEPLINK");
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [taxId, setTaxId] = useState("");
    const [paymentAmount, setPaymentAmount] = useState("");
    const [loading, setLoading] = useState(false);
    const [qrCodeUrl, setQrCodeUrl] = useState("");

    const { t } = useTranslation();
    const qrUrlDownloadClick = useRef();
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        if (location.state && location.state.country && location.state.description && location.state.paymentAmount) {
            if (location.state.user && location.state.user.name) {
                setUsername(location.state.user.name)
            }
            if (location.state.user && location.state.user.email) {
                setEmail(location.state.user.email)
            }
            if (location.state.user && location.state.user.taxId) {
                setTaxId(location.state.user.taxId)
            }
            if (location.state.paymentAmount) {
                setPaymentAmount(location.state.paymentAmount)
            }
        } else {
            history.push("/")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (qrCodeUrl) {
            qrUrlDownloadClick.current.click();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [qrCodeUrl]);


    const handleSubmit = (e) => {
        e.preventDefault();

        const emailRegex = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;

        if (!username || !email || !taxId || !paymentMethod) {
            mySwal.fire(t("Error"), t("Please fill in all the required fields"), "error");
        } else if (email && !emailRegex.test(email)) {
            mySwal.fire(t("Error"), t("Invalid Email Address"), "error");
        } else if (taxId.length !== 11 && taxId.length !== 14) {
            mySwal.fire(t("Error"), t("11 digits for CPF or 14 digits for CNPJ"), "error");
        } else if (location.state.user && location.state.pkg && token) {
            // reload token
            mySwal
                .fire({
                    title: t("Confirmation"),
                    html: <Trans i18nKey="Are you sure you want to purchase {{ tokenAmount }} e-Token for {{ currency }}{{ paymentAmount }}?">Are you sure you want to purchase {{ tokenAmount: location.state.pkg.token }} e-Token for {{ currency: currency(location.state.country) }}{{ paymentAmount: parseFloat(location.state.pkg.price).toFixed(2) }}?</Trans>,
                    icon: "question",
                    showCancelButton: true,
                    cancelButtonText: t("No"),
                    confirmButtonText: t("Yes"),
                    reverseButtons: STORETYPE === "cleanpro" ? true : false,
                    confirmButtonColor: STORETYPE === "cleanpro" ? "#004FC1" : STORETYPE === "washup" ? "#004c64" : "#2778c4",
                    customClass: {
                        confirmButton: STORETYPE === "cleanpro" ? "cleanpro-swal-btn confirm-pagbank-payment" : STORETYPE === "washup" ? "washup-swal-btn confirm-pagbank-payment" : "confirm-pagbank-payment",
                        cancelButton: STORETYPE === "cleanpro" ? "cleanpro-swal-secondary-btn cancel-pagbank-payment" : STORETYPE === "washup" ? "washup-swal-btn cancel-pagbank-payment" : "cancel-pagbank-payment",
                    },
                })
                .then((click) => {
                    if (click.isConfirmed && click.value) {
                        setLoading(true)

                        let sendThis = {
                            userId: location.state.user.id,
                            pkgId: location.state.pkg.id,
                            paymentMethod,
                            taxId,
                            email,
                            username
                        }
                        axios
                            .post("/api/token/pagbankPurchase", sendThis, { headers: { "Content-Type": "application/json", "auth-token": token } })
                            .then(res => {
                                setLoading(false);
                                if (paymentMethod === "DEEPLINK") {
                                    window.location.href = res.data.data;
                                } else if (paymentMethod === "PIX") {
                                    setQrCodeUrl(res.data.data)
                                    history.push("/payment/qr", { url: res.data.data, transactionId: res.data.transactionId })
                                }
                            })
                            .catch(err => {
                                mySwal.fire(t("Error"), t(err.response.data.error), "error");
                                setLoading(false);
                            });
                    }
                });
        } else if (location.state.endPoint && location.state.sendThis) {
            // machine payment / Drop n Go
            mySwal
                .fire({
                    title: t("Confirmation"),
                    text: `${t("Pay")} ${currency(location.state.country)} ${paymentAmount} ${t("to")} ${location.state.description}?`,
                    icon: "question",
                    showCancelButton: true,
                    cancelButtonText: t("No"),
                    confirmButtonText: t("Yes"),
                    reverseButtons: STORETYPE === "cleanpro" ? true : false,
                    confirmButtonColor: STORETYPE === "cleanpro" ? "#004FC1" : STORETYPE === "washup" ? "#004c64" : "#2778c4",
                    customClass: {
                        confirmButton: STORETYPE === "cleanpro" ? "cleanpro-swal-btn confirm-pagbank-payment" : STORETYPE === "washup" ? "washup-swal-btn confirm-pagbank-payment" : "confirm-pagbank-payment",
                        cancelButton: STORETYPE === "cleanpro" ? "cleanpro-swal-secondary-btn cancel-pagbank-payment" : STORETYPE === "washup" ? "washup-swal-btn cancel-pagbank-payment" : "cancel-pagbank-payment",
                    },
                })
                .then((click) => {
                    if (click.isConfirmed && click.value) {
                        setLoading(true)

                        let sendThis = {
                            ...location.state.sendThis,
                            paymentMethod,
                            taxId,
                            email,
                            username
                        }
                        axios
                            .post(location.state.endPoint, sendThis)
                            .then(res => {
                                setLoading(false);
                                saveTransactionId(res.data.transactionId)
                                if (paymentMethod === "DEEPLINK") {
                                    window.location.href = res.data.data;
                                } else if (paymentMethod === "PIX") {
                                    setQrCodeUrl(res.data.data)
                                    history.push("/payment/qr", { url: res.data.data, transactionId: res.data.transactionId, kiosk: location.state.sendThis.kiosk })
                                }
                            })
                            .catch(err => {
                                mySwal.fire(t("Error"), t(err.response.data.error), "error");
                                setLoading(false);
                            });
                    }
                });
        }
    }

    return (
        <div id="setting-page" className="user-faq">
            <div id="setting-main">
                <div id="user-header">
                    <h5><i onClick={e => history.goBack()} className="bi bi-chevron-left me-3"></i> <strong>PagBank</strong></h5>
                </div>

                <div id="user-body" className="px-4">
                    <form onSubmit={handleSubmit}>
                        <div className="form-group pt-3">
                            <h6>{t("Payment Amount")}</h6>
                            <p>{currency(location.state?.country)} {parseFloat(paymentAmount).toFixed(2)}</p>
                        </div>

                        <div className="form-group">
                            <h6>{t("Description")}</h6>
                            <p>{location.state.description}</p>
                        </div>

                        <div className="form-group">
                            <label htmlFor="username">{t("Name")}</label>
                            <input
                                type="text"
                                id="username"
                                name="username"
                                placeholder={t("Name")}
                                value={username}
                                onChange={e => setUsername(e.target.value)}
                                required
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="email">{t("Email")}</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                placeholder={t("Email")}
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                                required
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="taxId">{t("Tax ID (CPF/CNPJ Number)")}</label>
                            <input
                                type="number"
                                id="taxId"
                                name="taxId"
                                placeholder={t("Tax ID")}
                                value={taxId}
                                onChange={e => setTaxId(e.target.value)}
                                required
                            />
                        </div>

                        <div className="form-group">
                            <h6>{t("Payment Method")}</h6>
                            <label htmlFor="radio1">
                                <input type="radio" name="paymentMethod" id="radio1" value="DEEPLINK" className="with-gap" checked={paymentMethod === "DEEPLINK"} onChange={e => setPaymentMethod(e.target.value)} />
                                <span style={{ color: "black" }}>{t("PagBank App")}</span>
                            </label><br />

                            <label htmlFor="radio2">
                                <input type="radio" name="paymentMethod" id="radio2" value="PIX" className="with-gap" checked={paymentMethod === "PIX"} onChange={e => setPaymentMethod(e.target.value)} />
                                <span style={{ color: "black" }}>{t("PIX QR Code")}</span>
                            </label>
                        </div>

                        {
                            loading ? (
                                <div className="text-center">
                                    <div className={process.env.REACT_APP_STORETYPE === "cuci" ? "spinner-border text-warning" : "spinner-border text-primary"} role="status">
                                        <span className="sr-only">Loading ...</span>
                                    </div>
                                </div>
                            ) : (
                                <input
                                    id="pagbank-payment"
                                    type="submit"
                                    value={t("Pay")}
                                    className="btn big-button form-control"
                                />
                            )
                        }
                    </form>
                </div>
            </div>
            {qrCodeUrl ? <a className="btn btn-primary" ref={qrUrlDownloadClick} href={`${apiUrl}/api/user/downloadQr?url=${qrCodeUrl}`} download={`qr_${dateFormat(new Date())}_${timeFormat(new Date())}.png`} hidden>{t("Download")}</a> : null}
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        token: state.user.token,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
      saveTransactionId: (data) => dispatch(saveTransactionId(data)),
    };
  };

export default connect(mapStateToProps, mapDispatchToProps)(PagBank);