import { useState, useEffect, useRef } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import axios from "axios";
import { useTranslation, Trans } from "react-i18next";
import { currency, dateFormat, timeFormat } from "../../../../dynamicController";
import { Modal } from "react-bootstrap";

import insufficientToken from "../../../../assets/images/icons/icn-creditInsufficient.png";
import CleanproVending from "./Vending/CleanproVending";
import LaundroVending from "./Vending/LaundroVending";

const mySwal = withReactContent(Swal);
const STORETYPE = process.env.REACT_APP_STORETYPE;
const apiUrl = process.env.REACT_APP_API_URL;

const VendingMachine = ({ machine, user, history, outlet, theme }) => {
	const [amount, setAmount] = useState(STORETYPE === "cuci" ? 2 : 1);
	const [insufficient, setInsufficient] = useState(false);
	const [razerBody, setRazerBody] = useState(null);
	const [duitnowUrl, setDuitnowUrl] = useState("");
	const [ghlBody, setGhlBody] = useState(null);

	const razerButtonClick = useRef();
	const duitnowDownloadClick = useRef();
	const ghlButtonClick = useRef();

	const { t } = useTranslation();

	useEffect(() => {
		if (razerBody) {
			razerButtonClick.current.click();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [razerBody]);

	useEffect(() => {
		if (duitnowUrl) {
			duitnowDownloadClick.current.click();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [duitnowUrl]);

	useEffect(() => {
		if (ghlBody) {
			ghlButtonClick.current.click();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ghlBody]);

	const minusAmount = () => {
		const step = parseFloat(machine.step || 1);
		const newAmount = amount - step;
		if (newAmount) setAmount(newAmount);
	};

	const addAmount = () => {
		const step = parseFloat(machine.step || 1);
		const newAmount = amount + step;
		if (newAmount <= 100) setAmount(newAmount);
	};

	const handleSubmit = e => {
		if (amount && amount > 0) {
			e.target.disabled = true;
			if (machine.online && machine.outletStatus === "online") {
				if (user && user.tokenSetting && user.epayment) {
					// E-payment or E-token
					mySwal
						.fire({
							title: STORETYPE === "cleanpro" ? t("Payment methods") : t("Payment Method"),
							text: STORETYPE === "cleanpro" ? t("Tap to proceed with your preferred mode of payment") : t("Would you like to pay with?"),
							showDenyButton: true,
							showCancelButton: true,
							cancelButtonText: machine.duitnow ? t("Duitnow QR code") : t("Cancel"),
							denyButtonText: STORETYPE === "cleanpro" ? `${t("E-wallet or credit/debit cards")} >` : t("E-Wallet"),
							confirmButtonText: STORETYPE === "cleanpro" ? t("Cleanpro Plus credits") : t("e-Token"),
							denyButtonColor: STORETYPE === "cleanpro" ? "#004FC1" : STORETYPE === "washup" ? "#004c64" : "#67b668",
							confirmButtonColor: STORETYPE === "cleanpro" ? "#004FC1" : STORETYPE === "washup" ? "#CFA82C" : "#2778c4",
							customClass: {
								confirmButton: STORETYPE === "cleanpro" ? "cleanpro-swal-big-primary-btn e-wallet" : STORETYPE === "washup" ? "washup-swal-btn e-wallet" : "e-wallet",
								denyButton: STORETYPE === "cleanpro" ? "cleanpro-swal-big-primary-btn e-token" : STORETYPE === "washup" ? "washup-swal-btn e-token" : "e-token",
								cancelButton: STORETYPE === "cleanpro" ? "cleanpro-swal-big-secondary-btn cancel-payment" : STORETYPE === "washup" ? "washup-swal-btn cancel-payment" : "cancel-payment"
							}
						})
						.then(click => {
							if (click.isDenied) {
								// E WALLET PAYMENT
								let sendThis = {
									amount: parseFloat(amount).toFixed(2),
									paymentAmount: parseFloat(amount).toFixed(2),
									machine,
									outlet
								};

								let endPoint = "/api/nonuser/payment";

								if (user) {
									sendThis.voucherId = null;
									sendThis.memberId = user.memberId;
									endPoint = "/api/user/payment";
								}

								mySwal
									.fire({
										title: t("Confirmation"),
										text: `${t("Pay")} ${currency(machine.country)} ${parseFloat(amount).toFixed(2)} ${t("to")} ${machine.name}?`,
										icon: "question",
										showCancelButton: true,
										cancelButtonText: STORETYPE === "cleanpro" ? t("Cancel") : t("No"),
										confirmButtonText: STORETYPE === "cleanpro" ? t("Pay now") : t("OK"),
										reverseButtons: STORETYPE === "cleanpro" ? true : false,
										confirmButtonColor: STORETYPE === "cleanpro" ? "#004FC1" : STORETYPE === "washup" ? "#004c64" : "#2778c4",
										customClass: {
											confirmButton: STORETYPE === "cleanpro" ? "cleanpro-swal-btn" : STORETYPE === "washup" ? "washup-swal-btn" : "",
											cancelButton: STORETYPE === "cleanpro" ? "cleanpro-swal-secondary-btn" : STORETYPE === "washup" ? "washup-swal-btn" : ""
										}
									})
									.then(result => {
										if (result.isConfirmed) {
											if (machine.outletType === "pagbank" && parseFloat(amount) > 0) {
												let data = {
													country: machine.country,
													description: machine.name,
													paymentAmount: parseFloat(amount).toFixed(2),
													user,
													endPoint,
													sendThis,
												}
												history.push("/payment/pagbank", data)
											} else {
												mySwal
													.fire({
														text: t("Processing ..."),
														allowOutsideClick: false,
														didOpen: () => {
															mySwal.showLoading();
															axios
																.post(endPoint, sendThis)
																.then(res => {
																	if (res.status === 200 && res.statusText === "OK") {
																		if (res.data.status === "rm") {
																			e.target.disabled = false;
																			mySwal.close();
																			window.location.href = res.data.data;
																		} else if (res.data.status === "laundro") {
																			e.target.disabled = false;
																			mySwal.close();
																			history.push("/thankyou");
																		} else if (res.data.status === "razer") {
																			e.target.disabled = false;
																			mySwal.close();
																			setRazerBody(res.data.data)
																		} else if (res.data.status === "duitnow") {
																			e.target.disabled = false;
																			mySwal.close();
																			setDuitnowUrl(res.data.data)
																			history.push("/guide/duitnow", { url: res.data.data, transactionId: res.data.transactionId })
																		} else if (res.data.status === "ghl") {
																			e.target.disabled = false;
																			mySwal.close();
																			setGhlBody(res.data.data)
																		}
																	} else {
																		e.target.disabled = false;
																		mySwal.fire(t("Error"), t("Response Error"), "error");
																	}
																})
																.catch(err => mySwal.fire(t("Error"), t(err.response.data.error), "error").then(() => (e.target.disabled = false)));
														}
													})
													.then(() => {
														e.target.disabled = false;
													});
											}
										} else {
											e.target.disabled = false;
										}
									});
							} else if (click.isConfirmed) {
								// TOKEN PAYMENT
								if (parseFloat(user.token) < amount) {
									if (STORETYPE === "cleanpro") {
										setInsufficient(true);
										e.target.disabled = false;
									} else {
										mySwal
											.fire({
												title: t("Insufficient e-Tokens"),
												text: t("Reload your e-token now?"),
												showCancelButton: true,
												cancelButtonText: t("Cancel"),
												confirmButtonText: t("Reload now!"),
												confirmButtonColor: STORETYPE === "washup" ? "#004c64" : "#2778c4",
												customClass: {
													confirmButton: STORETYPE === "washup" ? "washup-swal-btn reload-token" : "reload-token",
													cancelButton: STORETYPE === "washup" ? "washup-swal-btn cancel-reload-token" : "cancel-reload-token"
												}
											})
											.then(result => {
												if (result.isConfirmed && result.value) history.push("/user/reload");
												e.target.disabled = false;
											});
									}
								} else {
									const sendThis = {
										amount: parseFloat(amount).toFixed(2),
										paymentAmount: parseFloat(amount).toFixed(2),
										machine,
										memberId: user.memberId,
										token: true,
										outlet
									};

									mySwal
										.fire({
											title: STORETYPE === "cleanpro" ? t("Payment Confirmation") : t("Confirmation"),
											html: `${t("You have")} <strong>${user.token}</strong> ${STORETYPE === "cleanpro" ? t("credits") : t("e-Tokens")}.<br />${t("Pay")} <strong>${parseFloat(amount).toFixed(2)}</strong> ${STORETYPE === "cleanpro" ? t("credits") : t("e-Tokens")} ${("to")} <strong>${machine.name}</strong>?`,
											icon: "question",
											showCancelButton: true,
											cancelButtonText: STORETYPE === "cleanpro" ? t("Cancel") : t("No"),
											confirmButtonText: STORETYPE === "cleanpro" ? t("Pay now") : t("OK"),
											reverseButtons: STORETYPE === "cleanpro" ? true : false,
											confirmButtonColor: STORETYPE === "cleanpro" ? "#004FC1" : STORETYPE === "washup" ? "#004c64" : "#2778c4",
											customClass: {
												confirmButton: STORETYPE === "cleanpro" ? "cleanpro-swal-btn" : STORETYPE === "washup" ? "washup-swal-btn" : "",
												cancelButton: STORETYPE === "cleanpro" ? "cleanpro-swal-secondary-btn" : STORETYPE === "washup" ? "washup-swal-btn" : "",
											}
										})
										.then(result => {
											if (result.isConfirmed) {
												mySwal
													.fire({
														text: t("Processing ..."),
														allowOutsideClick: false,
														didOpen: () => {
															mySwal.showLoading();
															axios
																.post("/api/user/payment", sendThis)
																.then(res => {
																	if (res.status === 200 && res.statusText === "OK") {
																		if (res.data.status === "rm") {
																			e.target.disabled = false;
																			mySwal.close();
																			window.location.href = res.data.data;
																		} else if (res.data.status === "laundro") {
																			e.target.disabled = false;
																			mySwal.close();
																			history.push("/thankyou");
																		} else if (res.data.status === "razer") {
																			e.target.disabled = false;
																			mySwal.close();
																			setRazerBody(res.data.data)
																		} else if (res.data.status === "duitnow") {
																			e.target.disabled = false;
																			mySwal.close();
																			setDuitnowUrl(res.data.data)
																			history.push("/guide/duitnow", { url: res.data.data, transactionId: res.data.transactionId })
																		} else if (res.data.status === "ghl") {
																			e.target.disabled = false;
																			mySwal.close();
																			setGhlBody(res.data.data)
																		}
																	} else {
																		e.target.disabled = false;
																		mySwal.fire(t("Error"), t("Response Error"), "error");
																	}
																})
																.catch(err => {
																	mySwal.fire(t("Error"), t(err.response.data.error), "error").then(() => (e.target.disabled = false));
																});
														}
													})
													.then(() => {
														e.target.disabled = false;
													});
											} else {
												e.target.disabled = false;
											}
										});
								}
							} else if (click.isDismissed && click.dismiss === "cancel" && machine.duitnow) {
								// Pay with Duitnow QR code
								let sendThis = {
									amount: parseFloat(amount).toFixed(2),
									paymentAmount: parseFloat(amount).toFixed(2),
									machine,
									outlet,
									duitnow: true
								};

								let endPoint = "/api/nonuser/payment";

								if (user) {
									sendThis.voucherId = null;
									sendThis.memberId = user.memberId;
									endPoint = "/api/user/payment";
								}

								mySwal
									.fire({
										title: t("Confirmation"),
										html: <Trans i18nKey="The Duitnow QR code will be downloaded and you need to open your banking app to make the payment. Pay {{ currency }}{{ totalPaymentAmount }} to {{ machineName }}?">The Duitnow QR code will be downloaded and you need to open your banking app to make the payment. Pay {{ currency: currency(machine.country) }}{{ totalPaymentAmount: parseFloat(amount).toFixed(2) }} to {{ machineName: machine.name }}?</Trans>,
										icon: "question",
										showCancelButton: true,
										cancelButtonText: STORETYPE === "cleanpro" ? t("Cancel") : t("No"),
										confirmButtonText: STORETYPE === "cleanpro" ? t("Pay now") : t("OK"),
										reverseButtons: STORETYPE === "cleanpro" ? true : false,
										confirmButtonColor: STORETYPE === "cleanpro" ? "#004FC1" : STORETYPE === "washup" ? "#004c64" : "#2778c4",
										customClass: {
											confirmButton: STORETYPE === "cleanpro" ? "cleanpro-swal-btn" : STORETYPE === "washup" ? "washup-swal-btn" : "",
											cancelButton: STORETYPE === "cleanpro" ? "cleanpro-swal-secondary-btn" : STORETYPE === "washup" ? "washup-swal-btn" : ""
										}
									})
									.then(result => {
										if (result.isConfirmed) {
											mySwal
												.fire({
													text: t("Processing ..."),
													allowOutsideClick: false,
													didOpen: () => {
														mySwal.showLoading();
														axios
															.post(endPoint, sendThis)
															.then(res => {
																if (res.status === 200 && res.statusText === "OK") {
																	if (res.data.status === "rm") {
																		e.target.disabled = false;
																		mySwal.close();
																		window.location.href = res.data.data;
																	} else if (res.data.status === "laundro") {
																		e.target.disabled = false;
																		mySwal.close();
																		history.push("/thankyou");
																	} else if (res.data.status === "razer") {
																		e.target.disabled = false;
																		mySwal.close();
																		setRazerBody(res.data.data)
																	} else if (res.data.status === "duitnow") {
																		e.target.disabled = false;
																		mySwal.close();
																		setDuitnowUrl(res.data.data)
																		history.push("/guide/duitnow", { url: res.data.data, transactionId: res.data.transactionId })
																	} else if (res.data.status === "ghl") {
																		e.target.disabled = false;
																		mySwal.close();
																		setGhlBody(res.data.data)
																	}
																} else {
																	e.target.disabled = false;
																	mySwal.fire(t("Error"), t("Response Error"), "error");
																}
															})
															.catch(err => mySwal.fire(t("Error"), t(err.response.data.error), "error").then(() => (e.target.disabled = false)));
													}
												})
												.then(() => {
													e.target.disabled = false;
												});
										} else {
											e.target.disabled = false;
										}
									});
							} else {
								e.target.disabled = false;
							}
						});
				} else if (user && user.tokenSetting && !user.epayment) {
					// Only E-Token
					if (parseFloat(user.token) < amount) {
						if (STORETYPE === "cleanpro") {
							setInsufficient(true);
							e.target.disabled = false;
						} else {
							mySwal
								.fire({
									title: t("Insufficient e-Tokens"),
									text: t("Reload your e-token now?"),
									showCancelButton: true,
									cancelButtonText: t("Cancel"),
									confirmButtonText: t("Reload now!"),
									confirmButtonColor: STORETYPE === "washup" ? "#004c64" : "#2778c4",
									customClass: {
										confirmButton: STORETYPE === "washup" ? "washup-swal-btn reload-token" : "reload-token",
										cancelButton: STORETYPE === "washup" ? "washup-swal-btn cancel-reload-token" : "cancel-reload-token"
									}
								})
								.then(result => {
									if (result.isConfirmed && result.value) history.push("/user/reload");
									e.target.disabled = false;
								});
						}
					} else {
						const sendThis = {
							amount: parseFloat(amount).toFixed(2),
							paymentAmount: parseFloat(amount).toFixed(2),
							machine,
							memberId: user.memberId,
							token: true,
							outlet
						};

						mySwal
							.fire({
								title: t("Confirmation"),
								html: `${t("You have")} <strong>${user.token}</strong> ${STORETYPE === "cleanpro" ? t("credits") : t("e-Tokens")}.<br />${t("Pay")} <strong>${parseFloat(amount).toFixed(2)}</strong> ${STORETYPE === "cleanpro" ? t("credits") : t("e-Tokens")} ${("to")} <strong>${machine.name}</strong>?`,
								icon: "question",
								showCancelButton: true,
								cancelButtonText: STORETYPE === "cleanpro" ? t("Cancel") : t("No"),
								confirmButtonText: STORETYPE === "cleanpro" ? t("Pay now") : t("OK"),
								reverseButtons: STORETYPE === "cleanpro" ? true : false,
								confirmButtonColor: STORETYPE === "cleanpro" ? "#004FC1" : STORETYPE === "washup" ? "#004c64" : "#2778c4",
								customClass: {
									confirmButton: STORETYPE === "cleanpro" ? "cleanpro-swal-btn" : STORETYPE === "washup" ? "washup-swal-btn" : "",
									cancelButton: STORETYPE === "cleanpro" ? "cleanpro-swal-secondary-btn" : STORETYPE === "washup" ? "washup-swal-btn" : "",
								}
							})
							.then(result => {
								if (result.isConfirmed) {
									mySwal
										.fire({
											text: t("Processing ..."),
											allowOutsideClick: false,
											didOpen: () => {
												mySwal.showLoading();
												axios
													.post("/api/user/payment", sendThis)
													.then(res => {
														if (res.status === 200 && res.statusText === "OK") {
															if (res.data.status === "rm") {
																e.target.disabled = false;
																mySwal.close();
																window.location.href = res.data.data;
															} else if (res.data.status === "laundro") {
																e.target.disabled = false;
																mySwal.close();
																history.push("/thankyou");
															} else if (res.data.status === "razer") {
																e.target.disabled = false;
																mySwal.close();
																setRazerBody(res.data.data)
															} else if (res.data.status === "duitnow") {
																e.target.disabled = false;
																mySwal.close();
																setDuitnowUrl(res.data.data)
																history.push("/guide/duitnow", { url: res.data.data, transactionId: res.data.transactionId })
															} else if (res.data.status === "ghl") {
																e.target.disabled = false;
																mySwal.close();
																setGhlBody(res.data.data)
															}
														} else {
															e.target.disabled = false;
															mySwal.fire(t("Error"), t("Response Error"), "error");
														}
													})
													.catch(err => {
														mySwal.fire(t("Error"), t(err.response.data.error), "error").then(() => (e.target.disabled = false));
													});
											}
										})
										.then(() => {
											e.target.disabled = false;
										});
								} else {
									e.target.disabled = false;
								}
							});
					}
				} else {
					// Only E-payment
					if (machine.duitnow) {
						// E-wallet or Duitnow QR code
						mySwal
							.fire({
								title: STORETYPE === "cleanpro" ? t("Payment methods") : t("Payment Method"),
								text: STORETYPE === "cleanpro" ? t("Tap to proceed with your preferred mode of payment") : t("Would you like to pay with?"),
								showDenyButton: true,
								showCancelButton: true,
								cancelButtonText: t("Cancel"),
								denyButtonText: STORETYPE === "cleanpro" ? `${t("E-wallet or credit/debit cards")} >` : t("E-Wallet"),
								confirmButtonText: t("Duitnow QR code"),
								denyButtonColor: STORETYPE === "cleanpro" ? "#004FC1" : STORETYPE === "washup" ? "#004c64" : "#67b668",
								confirmButtonColor: STORETYPE === "cleanpro" ? "#004FC1" : STORETYPE === "washup" ? "#CFA82C" : "#2778c4",
								customClass: {
									confirmButton: STORETYPE === "cleanpro" ? "cleanpro-swal-big-primary-btn duitnow" : STORETYPE === "washup" ? "washup-swal-btn duitnow" : "duitnow",
									denyButton: STORETYPE === "cleanpro" ? "cleanpro-swal-big-primary-btn e-wallet" : STORETYPE === "washup" ? "washup-swal-btn e-wallet" : "e-wallet",
									cancelButton: STORETYPE === "cleanpro" ? "cleanpro-swal-big-secondary-btn cancel-payment" : STORETYPE === "washup" ? "washup-swal-btn cancel-payment" : "cancel-payment"
								},
							})
							.then((click) => {
								if (click.isDenied) {
									// E WALLET PAYMENT
									let sendThis = {
										amount: parseFloat(amount).toFixed(2),
										paymentAmount: parseFloat(amount).toFixed(2),
										machine,
										outlet
									};

									let endPoint = "/api/nonuser/payment";

									if (user) {
										sendThis.voucherId = null;
										sendThis.memberId = user.memberId;
										endPoint = "/api/user/payment";
									}

									mySwal
										.fire({
											title: t("Confirmation"),
											text: `${t("Pay")} ${currency(machine.country)} ${parseFloat(amount).toFixed(2)} ${t("to")} ${machine.name}?`,
											icon: "question",
											showCancelButton: true,
											cancelButtonText: STORETYPE === "cleanpro" ? t("Cancel") : t("No"),
											confirmButtonText: STORETYPE === "cleanpro" ? t("Pay now") : t("OK"),
											reverseButtons: STORETYPE === "cleanpro" ? true : false,
											confirmButtonColor: STORETYPE === "cleanpro" ? "#004FC1" : STORETYPE === "washup" ? "#004c64" : "#2778c4",
											customClass: {
												confirmButton: STORETYPE === "cleanpro" ? "cleanpro-swal-btn" : STORETYPE === "washup" ? "washup-swal-btn" : "",
												cancelButton: STORETYPE === "cleanpro" ? "cleanpro-swal-secondary-btn" : STORETYPE === "washup" ? "washup-swal-btn" : ""
											}
										})
										.then(result => {
											if (result.isConfirmed) {
												if (machine.outletType === "pagbank" && parseFloat(amount) > 0) {
													let data = {
														country: machine.country,
														description: machine.name,
														paymentAmount: parseFloat(amount).toFixed(2),
														user,
														endPoint,
														sendThis,
													}
													history.push("/payment/pagbank", data)
												} else {
													mySwal
														.fire({
															text: t("Processing ..."),
															allowOutsideClick: false,
															didOpen: () => {
																mySwal.showLoading();
																axios
																	.post(endPoint, sendThis)
																	.then(res => {
																		if (res.status === 200 && res.statusText === "OK") {
																			if (res.data.status === "rm") {
																				e.target.disabled = false;
																				mySwal.close();
																				window.location.href = res.data.data;
																			} else if (res.data.status === "laundro") {
																				e.target.disabled = false;
																				mySwal.close();
																				history.push("/thankyou");
																			} else if (res.data.status === "razer") {
																				e.target.disabled = false;
																				mySwal.close();
																				setRazerBody(res.data.data)
																			} else if (res.data.status === "duitnow") {
																				e.target.disabled = false;
																				mySwal.close();
																				setDuitnowUrl(res.data.data)
																				history.push("/guide/duitnow", { url: res.data.data, transactionId: res.data.transactionId })
																			} else if (res.data.status === "ghl") {
																				e.target.disabled = false;
																				mySwal.close();
																				setGhlBody(res.data.data)
																			}
																		} else {
																			e.target.disabled = false;
																			mySwal.fire(t("Error"), t("Response Error"), "error");
																		}
																	})
																	.catch(err => mySwal.fire(t("Error"), t(err.response.data.error), "error")
																		.then(() => {
																			e.target.disabled = false
																			if (err.response.data.error === "Please log in to make the payment") {
																				history.push("/");
																			}
																		}));
															}
														})
														.then(() => {
															e.target.disabled = false;
														});
												}
											} else {
												e.target.disabled = false;
											}
										});
								} else if (click.isConfirmed) {
									// Pay with Duitnow QR code
									let sendThis = {
										amount: parseFloat(amount).toFixed(2),
										paymentAmount: parseFloat(amount).toFixed(2),
										machine,
										outlet,
										duitnow: true
									};

									let endPoint = "/api/nonuser/payment";

									if (user) {
										sendThis.voucherId = null;
										sendThis.memberId = user.memberId;
										endPoint = "/api/user/payment";
									}

									mySwal
										.fire({
											title: t("Confirmation"),
											html: <Trans i18nKey="The Duitnow QR code will be downloaded and you need to open your banking app to make the payment. Pay {{ currency }}{{ totalPaymentAmount }} to {{ machineName }}?">The Duitnow QR code will be downloaded and you need to open your banking app to make the payment. Pay {{ currency: currency(machine.country) }}{{ totalPaymentAmount: parseFloat(amount).toFixed(2) }} to {{ machineName: machine.name }}?</Trans>,
											icon: "question",
											showCancelButton: true,
											cancelButtonText: STORETYPE === "cleanpro" ? t("Cancel") : t("No"),
											confirmButtonText: STORETYPE === "cleanpro" ? t("Pay now") : t("OK"),
											reverseButtons: STORETYPE === "cleanpro" ? true : false,
											confirmButtonColor: STORETYPE === "cleanpro" ? "#004FC1" : STORETYPE === "washup" ? "#004c64" : "#2778c4",
											customClass: {
												confirmButton: STORETYPE === "cleanpro" ? "cleanpro-swal-btn" : STORETYPE === "washup" ? "washup-swal-btn" : "",
												cancelButton: STORETYPE === "cleanpro" ? "cleanpro-swal-secondary-btn" : STORETYPE === "washup" ? "washup-swal-btn" : "",
											}
										})
										.then(result => {
											if (result.isConfirmed) {
												mySwal
													.fire({
														text: t("Processing ..."),
														allowOutsideClick: false,
														didOpen: () => {
															mySwal.showLoading();
															axios
																.post(endPoint, sendThis)
																.then(res => {
																	if (res.status === 200 && res.statusText === "OK") {
																		if (res.data.status === "rm") {
																			e.target.disabled = false;
																			mySwal.close();
																			window.location.href = res.data.data;
																		} else if (res.data.status === "laundro") {
																			e.target.disabled = false;
																			mySwal.close();
																			history.push("/thankyou");
																		} else if (res.data.status === "razer") {
																			e.target.disabled = false;
																			mySwal.close();
																			setRazerBody(res.data.data)
																		} else if (res.data.status === "duitnow") {
																			e.target.disabled = false;
																			mySwal.close();
																			setDuitnowUrl(res.data.data)
																			history.push("/guide/duitnow", { url: res.data.data, transactionId: res.data.transactionId })
																		} else if (res.data.status === "ghl") {
																			e.target.disabled = false;
																			mySwal.close();
																			setGhlBody(res.data.data)
																		}
																	} else {
																		e.target.disabled = false;
																		mySwal.fire(t("Error"), t("Response Error"), "error");
																	}
																})
																.catch(err => mySwal.fire(t("Error"), t(err.response.data.error), "error")
																	.then(() => {
																		e.target.disabled = false
																		if (err.response.data.error === "Please log in to make the payment") {
																			history.push("/");
																		}
																	}));
														}
													})
													.then(() => {
														e.target.disabled = false;
													});
											} else {
												e.target.disabled = false;
											}
										});
								} else {
									e.target.disabled = false;
								}
							})
					} else {
						// Only E-wallet
						let sendThis = {
							amount: parseFloat(amount).toFixed(2),
							paymentAmount: parseFloat(amount).toFixed(2),
							machine,
							outlet
						};

						let endPoint = "/api/nonuser/payment";

						if (user) {
							sendThis.voucherId = null;
							sendThis.memberId = user.memberId;
							endPoint = "/api/user/payment";
						}

						mySwal
							.fire({
								title: t("Confirmation"),
								text: `${t("Pay")} ${currency(machine.country)} ${parseFloat(amount).toFixed(2)} ${t("to")} ${machine.name}?`,
								icon: "question",
								showCancelButton: true,
								cancelButtonText: t("No"),
								confirmButtonText: t("OK"),
								confirmButtonColor: STORETYPE === "cleanpro" ? "#004FC1" : STORETYPE === "washup" ? "#004c64" : "#2778c4",
								customClass: {
									confirmButton: STORETYPE === "cleanpro" ? "cleanpro-swal-btn" : STORETYPE === "washup" ? "washup-swal-btn" : "",
									cancelButton: STORETYPE === "cleanpro" ? "cleanpro-swal-secondary-btn" : STORETYPE === "washup" ? "washup-swal-btn" : "",
								}
							})
							.then(result => {
								if (result.isConfirmed) {
									if (machine.outletType === "pagbank" && parseFloat(amount) > 0) {
										let data = {
											country: machine.country,
											description: machine.name,
											paymentAmount: parseFloat(amount).toFixed(2),
											user,
											endPoint,
											sendThis,
										}
										history.push("/payment/pagbank", data)
									} else {
										mySwal
											.fire({
												text: t("Processing ..."),
												allowOutsideClick: false,
												didOpen: () => {
													mySwal.showLoading();
													axios
														.post(endPoint, sendThis)
														.then(res => {
															if (res.status === 200 && res.statusText === "OK") {
																if (res.data.status === "rm") {
																	e.target.disabled = false;
																	mySwal.close();
																	window.location.href = res.data.data;
																} else if (res.data.status === "laundro") {
																	e.target.disabled = false;
																	mySwal.close();
																	history.push("/thankyou");
																} else if (res.data.status === "razer") {
																	e.target.disabled = false;
																	mySwal.close();
																	setRazerBody(res.data.data)
																} else if (res.data.status === "duitnow") {
																	e.target.disabled = false;
																	mySwal.close();
																	setDuitnowUrl(res.data.data)
																	history.push("/guide/duitnow", { url: res.data.data, transactionId: res.data.transactionId })
																} else if (res.data.status === "ghl") {
																	e.target.disabled = false;
																	mySwal.close();
																	setGhlBody(res.data.data)
																}
															} else {
																e.target.disabled = false;
																mySwal.fire(t("Error"), t("Response Error"), "error");
															}
														})
														.catch(err => mySwal.fire(t("Error"), t(err.response.data.error), "error")
															.then(() => {
																e.target.disabled = false
																if (err.response.data.error === "Please log in to make the payment") {
																	history.push("/");
																}
															}));
												}
											})
											.then(() => {
												e.target.disabled = false;
											});
									}
								} else {
									e.target.disabled = false;
								}
							});
					}
				}
			} else {
				mySwal
					.fire(t("Error"), t("This machine is currently offline, please try again later."), "error")
					.then(() => (e.target.disabled = false));
			}
		} else {
			mySwal.fire(t("Error"), t("Payment amount should be greater than 0"), "error")
		}
	};

	return (
		<div id="vending-machine" className="mt-4">
			{
				STORETYPE === "cleanpro"
					? <CleanproVending
						amount={amount}
						setAmount={setAmount}
						machine={machine}
						minusAmount={minusAmount}
						addAmount={addAmount}
						handleSubmit={handleSubmit}
						user={user}
					/>
					: <LaundroVending
						amount={amount}
						setAmount={setAmount}
						machine={machine}
						minusAmount={minusAmount}
						addAmount={addAmount}
						handleSubmit={handleSubmit}
					/>
			}

			{
				razerBody ? (
					<div>
						<form action={razerBody.paymentDomain} method="post">
							<input type="hidden" name="amount" value={razerBody.amount} />
							<input type="hidden" name="merchant_id" value={razerBody.merchant_id} />
							<input type="hidden" name="orderid" value={razerBody.orderid} />
							{razerBody.bill_name ? <input type="hidden" name="bill_name" value={razerBody.bill_name} /> : null}
							{razerBody.bill_email ? <input type="hidden" name="bill_email" value={razerBody.bill_email} /> : null}
							{razerBody.bill_mobile ? <input type="hidden" name="bill_mobile" value={razerBody.bill_mobile} /> : null}
							<input type="hidden" name="bill_desc" value={razerBody.bill_desc} />
							<input type="hidden" name="country" value={razerBody.country} />
							<input type="hidden" name="vcode" value={razerBody.vcode} />
							<input type="hidden" name="currency" value={razerBody.currency} />
							<input type="hidden" name="returnurl" value={razerBody.returnurl} />
							<input type="hidden" name="callbackurl" value={razerBody.callbackurl} />
							<input type="hidden" name="cancelurl" value={razerBody.cancelurl} />

							<input type="submit" name="submit" value="Pay with razer" hidden ref={razerButtonClick} />
						</form>
					</div>
				) : null
			}

			{duitnowUrl ? <a className="btn btn-primary" ref={duitnowDownloadClick} href={`${apiUrl}/api/user/downloadDuitnowQr?url=${duitnowUrl}`} download={`duitnow_${dateFormat(new Date())}_${timeFormat(new Date())}.png`} hidden>{t("Download")}</a> : null}

			{
				ghlBody ? (
					<div>
						<form name="frmPayment" method="post" action={ghlBody.frmPayment}>
							<input type="hidden" name="TransactionType" value={ghlBody.TransactionType} />
							<input type="hidden" name="PymtMethod" value={ghlBody.PymtMethod} />
							<input type="hidden" name="ServiceID" value={ghlBody.ServiceID} />
							<input type="hidden" name="PaymentID" value={ghlBody.PaymentID} />
							<input type="hidden" name="OrderNumber" value={ghlBody.OrderNumber} />
							<input type="hidden" name="PaymentDesc" value={ghlBody.PaymentDesc} />
							<input type="hidden" name="MerchantReturnURL" value={ghlBody.MerchantReturnURL} />
							<input type="hidden" name="Amount" value={ghlBody.Amount} />
							<input type="hidden" name="CurrencyCode" value={ghlBody.CurrencyCode} />
							<input type="hidden" name="CustIP" value={ghlBody.CustIP} />
							<input type="hidden" name="CustName" value={ghlBody.CustName} />
							<input type="hidden" name="CustEmail" value={ghlBody.CustEmail} />
							<input type="hidden" name="CustPhone" value={ghlBody.CustPhone} />
							<input type="hidden" name="HashValue" value={ghlBody.HashValue} />
							<input type="hidden" name="PageTimeout" value={ghlBody.PageTimeout} />

							<input type="submit" name="submit" value="Pay with GHL" hidden ref={ghlButtonClick} />
						</form>

					</div>
				) : null
			}
			<Modal
				show={insufficient}
				onHide={(e) => setInsufficient(false)}
				keyboard={false}
				centered
			>
				<Modal.Body>
					<div className={theme}>
						<img
							className="d-block"
							src={insufficientToken}
							style={{ width: "64px", margin: "0 auto" }}
							alt=""
						/>
						<h2 className="align-center my-3">{t("Insufficient balance")}</h2>
						<p className="text-center" style={{ fontSize: "16px", fontWeight: 300 }}>{t("Oops. You don't have enough balance to make the payment")}</p>
						<div className="d-flex justify-content-around pt-3">
							<button id="cleanpro-cancel-topup" onClick={() => setInsufficient(false)} style={{ height: "50px", width: "40%" }} className="btn secondary-button">
								{t("Back")}
							</button>
							<button id="cleanpro-topup-now" onClick={() => history.push("/user/reload")} className="btn big-button" style={{ width: "50%" }}>
								{t("Topup now")}
							</button>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</div>
	);
};

export default VendingMachine;
